import { combineReducers } from "redux";
import api from './apiSlice';
import auth from './authSlice';
import login from './loginSlice';
import kompas from './kompasSlice';
import nudges from './nudgesSlice';
import pushNotif from './pushNotifSlice';
import notification from './notificationSlice';

export default combineReducers({
  api,
  auth,
  login,
  kompas,
  nudges,
  pushNotif,
  notification,
});
