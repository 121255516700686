import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isShow: false,
  title: '',
  content: '',
  date: '',
  payload: null,
};

const authSlice = createSlice({
  name: 'pushNotif',
  initialState,
  reducers: {
    showNotification: (state, action) => {
      const {
        title,
        content = '',
        date = '',
        payload,
      } = action.payload;

      state.isShow = true;
      state.title = title;
      state.content = content;
      state.date = date;
      state.payload = payload;
    },
    hideNotification: (state) => {
      state.isShow = false;
      state.title = '';
      state.content = '';
      state.date = '';
      state.payload = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addDefaultCase((state, action) => {
  //     return {
  //       ...state,
  //       ...action.payload.auth,
  //     };
  //   });
  // },
});

export const { showNotification, hideNotification } = authSlice.actions;

export default authSlice.reducer;

export const getIsShow = (state) => state.pushNotif.isShow;
export const getTitle = (state) => state.pushNotif.title;
export const getContent = (state) => state.pushNotif.content;
export const getDate = (state) => state.pushNotif.date;
export const getPayload = (state) => state.pushNotif.payload;
