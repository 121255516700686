import { configureStore } from '@reduxjs/toolkit';
import { encryptTransform } from 'redux-persist-transform-encrypt';

import storage from './syncStorage';
import reducer from "./reducer/index";

//If it's on client side, create a store which will persist
const { persistStore, persistReducer } = require('redux-persist');

const persistConfig = {
  key: process.env.NEXT_PUBLIC_STORAGE_NAME,
  whitelist: ['auth', 'kompas', 'nudges'], // only counter will be persisted, add other reducers if needed
  storage, // if needed, use a safer storage
  transforms: [
    encryptTransform({
      secretKey: process.env.NEXT_PUBLIC_API_TOKEN,
    }),
  ],
};

// Create a new reducer with our existing reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Creating the store again
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

// This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature
store.__persistor = persistStore(store);

export { store };
